import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
    const { user, isAuthenticated } = useAuth0();
    return (
    isAuthenticated && (
    <div>
        <h1 className="profile">Profile</h1>
        <h1>{user.name}</h1>
    </div>
    )
    )
}

export default Profile;