import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import FixturesPage from './pages/FixturesPage';
import BetForm from './pages/RequestPublisher';
/*import LoginPage from './pages/LoginPage'; */
import WalletPage from './pages/WalletPage';
import NotFoundPage from './pages/NotFoundPage';
import LogInRedirect from './pages/LogInRedirect';
import UserRequestsPage from './pages/UserRequestsPage';
import { ENDPOINTS } from './config/endpoints';
import BetPage from './pages/BetPage';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Routes>
        {/* Home page route, redirects to the fixtures page */}
        <Route path={ENDPOINTS.NAVIGATION.HOME} element={<Navigate to={ENDPOINTS.NAVIGATION.FIXTURES} />} />

        {/* Route to display all fixtures */}
        <Route path={ENDPOINTS.NAVIGATION.FIXTURES} element={<FixturesPage />} />

        <Route path={ENDPOINTS.NAVIGATION.WALLET} element={<WalletPage />} />
        {/* Route to display the bet page for a specific fixture ARREGLAR PARA QUE ESTE CON ENDPOINTS*/}
        <Route path="/bet/:fixtureId" element={<BetPage />} />

        <Route path={ENDPOINTS.NAVIGATION.LOGINREDIRECT} element={<LogInRedirect />} />

        <Route path={ENDPOINTS.NAVIGATION.USER_REQUESTS} element={<UserRequestsPage />} />

        {/* Catch-all for any undefined routes (404 page) */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default App;
