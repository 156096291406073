import React, { useState } from 'react';

const FixturesFilter = ({ onFilter }) => {
  const [homeTeam, setHomeTeam] = useState('');
  const [awayTeam, setAwayTeam] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilter({ home: homeTeam, visit: awayTeam, date });
  };

  return (
    <form onSubmit={handleSubmit} className="fixtures-filter" style={{ display: 'flex', gap: '10px' }}>
      <div>
        <label htmlFor="homeTeam">Home Team:</label>
        <input
          type="text"
          id="homeTeam"
          value={homeTeam}
          onChange={(e) => setHomeTeam(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="awayTeam">Away Team:</label>
        <input
          type="text"
          id="awayTeam"
          value={awayTeam}
          onChange={(e) => setAwayTeam(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="date">Date:</label>
        <input
          type="date"
          id="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      <button type="submit">Filter</button>
    </form>
  );
};

export default FixturesFilter;
