import React from 'react';

const PageSelector = ({ currentPage, totalPages, onPageChange, hasNextPage, hasPreviousPage }) => {
  
  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handleLastPage = () => {
    onPageChange(totalPages);
  };

  const handlePreviousPage = () => {
    if (hasPreviousPage) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (hasNextPage) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
      <button onClick={handleFirstPage} disabled={currentPage === 1}>
        First
      </button>
      <button onClick={handlePreviousPage} disabled={!hasPreviousPage}>
        Previous
      </button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <button onClick={handleNextPage} disabled={!hasNextPage}>
        Next
      </button>
      <button onClick={handleLastPage} disabled={currentPage === totalPages}>
        Last
      </button>
    </div>
  );
};

export default PageSelector;
