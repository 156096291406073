import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { ENDPOINTS } from '../config/endpoints';

const WalletPage = () => {
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const { user, isLoading } = useAuth0();

    const handleAddFunds = async () => {
        const value = parseInt(amount);
        if (!isNaN(value) && value > 0) {
            try {
                const response = await axios.post(ENDPOINTS.API.WALLET_UPDATE(user.email), {value})
                fetchUserData();
            } catch (error) {
                console.error('Error updating wallet:', error);
            }
        }
    }

    const fetchUserData = async () => {
        try {
            const response = await axios.get(ENDPOINTS.API.USER_DATA(user.email));
            setBalance(response.data.user.funds);
            setUserEmail(response.data.user.email);
            console.log(response.data.user.funds);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    useEffect(() => {
        fetchUserData();
    }, [user]);

    if (isLoading) {
        return <p>Loading wallet...</p>;
    }

    return (
    <div>
        <h1>Wallet of {userEmail}</h1>
        <p>Current Balance: ${balance} </p>
        <input 
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder='Ingresa cantidad de Dinero a agregar a tu wallet'
        />
        <button onClick={handleAddFunds}>Agregar</button>
    </div>
    )
}

export default WalletPage;