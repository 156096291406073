import React from 'react';
import { Link } from 'react-router-dom';

const FixtureCard = ({ fixture, onDetailsClick }) => {
  return (
    <div className="fixture-card">
      <div className="team">
        <img 
          src={fixture.home_team.logo} 
          alt={`${fixture.home_team.name} logo`} 
          className="team-logo" 
        />
        <p>{fixture.home_team.name}</p>
      </div>
      <div className="vs">VS</div>
      <div className="team">
        <img 
          src={fixture.away_team.logo} 
          alt={`${fixture.away_team.name} logo`} 
          className="team-logo" 
        />
        <p>{fixture.away_team.name}</p>
      </div>
      <p className="fixture-date">{new Date(fixture.date).toLocaleDateString()}</p>
      
      <div className="button-container">
        <button className="details-button" onClick={() => onDetailsClick(fixture.id)}>
          Details
        </button>
        <Link to={`/bet/${fixture.id}`}>
          <button className='details-button'>Bet</button>
        </Link>
      </div>

    </div>
  );
};

export default FixtureCard;
