const BACKEND_URL = "https://api.coolgoat.ink";
const BACKEND_URL_REQUESTS = "https://api.coolgoat.ink/api/publish/";
const BACKEND_URL_WALLET_UPDATE = "https://api.coolgoat.ink/users/wallet/update/";
const BACKEND_URL_REQUESTS_RELATION = "https://api.coolgoat.ink/api/request/create/relation/";

const ENDPOINTS = {
    API: {
        REGISTER: `${BACKEND_URL}/auth/users/signup/`,
        LOGIN: `${BACKEND_URL}/auth/users/create/`,
        LOGOUT: `${BACKEND_URL}/auth/users/logout/`,
        AUTH0_CALLBACK: `${BACKEND_URL}/auth/users/auth0/callback/`,
        AUTH0_LOGOUT: `${BACKEND_URL}/auth/users/auth0/logout/`,
        FIXTURES: `${BACKEND_URL}/api/fixtures/`,
        BONDS: `${BACKEND_URL}/api/bonds/`,
        VALIDATION: `${BACKEND_URL}/api/fixtures/validate/`,
        REQUESTS: `${BACKEND_URL}/api/fixtures/requests/`,
        ODDS: `${BACKEND_URL}/api/odds`,

        USER_DATA: (user_email) => `${BACKEND_URL}/auth/users/${user_email}/`,
        WALLET_UPDATE: (user_email) => `${BACKEND_URL}/auth/users/${user_email}/wallet/`,
        REQUESTS_BY_USER_EMAIL: (user_email) => `${BACKEND_URL}/api/requests/${user_email}`,

        FIXTURES_FILTER: (home, visit, startDate, endDate) => {
            let url = `${BACKEND_URL}/api/fixtures/?`;
            if (home) url += `home=${home}&`;
            if (visit) url += `visit=${visit}&`;
            if (startDate && endDate) url += `start_date=${startDate}&end_date=${endDate}&`;
            return url.slice(0, -1);
        },
        FIXTURES_BY_ID: (fixture_id) => `${BACKEND_URL}/api/fixtures/${fixture_id}`,
    },
    NAVIGATION: {
        HOME: '/',
        LOGIN: '/login',
        REGISTER: '/register',
        PROFILE: '/profile',
        FIXTURES: '/fixtures',
        BONDS: '/bonds',
        // REQUESTS: '/requests',
        VALIDATION: '/validation',
        LOGOUT: '/logout',
        WALLET: '/wallet',
        LOGINREDIRECT: '/loginredirect',
        USER_REQUESTS: '/requests',
        // Dynamic navigation routes
        BONDS_BY_ID: (bond_id) => `/bonds/${bond_id}`,
        FIXTURES_BY_LEAGUE: (league_id) => `/fixtures/league/${league_id}`,
        FIXTURES_BY_TEAM: (team_id) => `/fixtures/team/${team_id}`,
    }
};

export { BACKEND_URL, BACKEND_URL_REQUESTS, BACKEND_URL_WALLET_UPDATE, ENDPOINTS, BACKEND_URL_REQUESTS_RELATION };
