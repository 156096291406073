import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_URL_REQUESTS_RELATION, ENDPOINTS } from '../config/endpoints'; 
import { v4 as uuidv4, validate } from 'uuid';
import { BACKEND_URL_REQUESTS } from '../config/endpoints';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';


const BetPage = () => {
  const navigate = useNavigate();
  const { fixtureId } = useParams(); // Obtener el ID del fixture desde la URL
  const [fixture, setFixture] = useState(null);
  const [odds, setOdds] = useState([]); // Almacenar los odds obtenidos del backend
  const [betAmount, setBetAmount] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBetType, setSelectedBetType] = useState({});
  const { user } = useAuth0();

  useEffect(() => {
    const fetchOddsData = async () => {
      try {

        const response = await axios.get(`${ENDPOINTS.API.ODDS}/${fixtureId}/`);
        console.log(response.data.odds);
        const odds = response.data.odds
        const last_odds  = odds.slice(-1);
        console.log(last_odds);
        setOdds(last_odds);
        
        const responseFixture = await axios.get(ENDPOINTS.API.FIXTURES_BY_ID(fixtureId));
        setFixture(responseFixture.data);
        console.log(responseFixture.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching fixture odds:', error);
        setError('Error fetching odds. Please try again later.');
        setLoading(false);
      }
    };

    fetchOddsData();
  }, [fixtureId]);

  const handleBetChange = (oddId, amount) => {
    const numericAmount = parseFloat(amount);

 
    if (isNaN(numericAmount) || numericAmount <= 0) {
        console.error('Please enter a valid amount greater than 0.');
        return; 
    }
    
    setBetAmount(prev => ({
      ...prev,
      [oddId]: amount,
    }));
  };

  const handleBetTypeChange = (id_in_match, value) => {
    setSelectedBetType(prevState => ({
      ...prevState,
      [id_in_match]: value,
    }));
  };

  const getResultFinal = (result, fixture) => {
    let result_final;
  
    if (result["1"]) {
      if (result["1"] === "Away") {
        result_final = fixture.away_team.name;
      } else if (result["1"] === "Home") {
        result_final = fixture.home_team.name;
      } else if (result["1"] === "Draw") {
        result_final = "---";
      }
    } else {
      result_final =  fixture.home_team.name;;
    }
  
    return result_final;
  };

  const handlePlaceBet = (oddId) => {
    
    console.log(`Placing bet of ${betAmount[oddId]} on odd ${oddId} for fixture ${fixtureId}`);
     
    if (!selectedBetType || !betAmount[oddId]) {
        console.error('Please select a value and enter a bet amount.');
        return; 
    }

    // Generar un ID único para request_id
    const requestId = uuidv4(); 
    
    const fixtureDetails = fixture; 
    const leagueName = fixtureDetails.league.name;
    const round = fixtureDetails.league.round;
    const date = fixtureDetails.date; // La fecha del fixture
    const result = getResultFinal(selectedBetType,fixture); // El valor seleccionado (Home, Draw, Away)
    const quantity = betAmount[oddId]; // Cantidad apostada desde el input
    const datetime = new Date().toISOString(); // Fecha y hora actual
    const validated = false; // Validado por el admin
    // Crear el objeto JSON
    const betData = {
        request_id: requestId,
        group_id: '25',
        fixture_id: fixtureDetails.id,
        league_name: leagueName,
        round: round,
        date: date,
        result: result,
        deposit_token: 'deposit_token',
        datetime: datetime,
        quantity: quantity,
        seller: 0,
        //validated: validated,
    };

    const relationData = {
        email: user.email,
        request_id: requestId,
    };

    axios.post(BACKEND_URL_REQUESTS, betData)
    .then(response => {
        console.log('Bet request submitted successfully:', response.data);
        navigate('/fixtures');
    })
    .catch(error => {
        console.error('There was an error submitting the bet request:', error);
    });

    axios.post(BACKEND_URL_REQUESTS_RELATION, relationData)
    .then(response => {
        console.log('Bet relation submitted successfully:', response.data);
    })
    .catch(error => {
        console.error('There was an error submitting the bet relation:', error);
    });
    };

  if (loading) return <p>Loading odds...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="bet-card">
  <h2>Betting on: {fixture.away_team.name} v/s {fixture.home_team.name}</h2>

  <div className="odds-container">
    {odds.map((odd) => (
      <div key={odd.id_in_match} className="odd-item">
        <h3>{odd.name}</h3>
        <div className="odds-values">
          {odd.values.map((value, index) => (
            <span key={index} className="odd-value">
              {value.value}: {value.odd}
            </span>
          ))}
        </div>
        
        <label>
          Select Option:
          <select onChange={(e) => handleBetTypeChange(odd.id_in_match, e.target.value)}>
            {odd.values.map((value, index) => (
              <option key={index} value={value.value}>
                {value.value}
              </option>
            ))}
          </select>
        </label>

        <label>
          Amount of bonds:
          <input
            type="number"
            value={betAmount[odd.id_in_match] || ''}
            onChange={(e) => handleBetChange(odd.id_in_match, e.target.value)}
          />
        </label>

        <button className="details-button" onClick={() => handlePlaceBet(odd.id_in_match)}>
          Place Bet
        </button>
      </div>
    ))}
  </div>
</div>
  );
};

export default BetPage;
