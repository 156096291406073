import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS } from "../config/endpoints";
import axios from "axios";
import { useEffect } from "react";

const LogInRedirect = () => {

    const { user, isAuthenticated, isLoading } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated && user && !isLoading) {
            const registerUser = async () => {
                try {
                    console.log(user.email);
                    await axios.post(ENDPOINTS.API.LOGIN, {
                        email: user.email,
                        funds: 10000,
                    });
                    console.log('Usuario registrado en el backend.');
                } catch (error) {
                    console.error('Error al registrar el usuario:', error);
                    console.log(user.email);
                }
            };
            console.log("Redireccionando...");
            registerUser();
            navigate(ENDPOINTS.NAVIGATION.FIXTURES)
        }

        
    }, [isAuthenticated, user, isLoading, navigate]);


    return (
        <div>
            <h1>LogInRedirect...</h1>
        </div>
    );
}

export default LogInRedirect;