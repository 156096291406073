import React, { useState, useEffect } from 'react';
import { ENDPOINTS } from '../config/endpoints';
import UserRequestCard from '../components/UserRequestCard';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Row, Col, Container } from 'react-bootstrap';

const UserRequestsList = () => {
    const [requests, setRequests] = useState([]);
    const { user, isLoading } = useAuth0();

    const fetchRequests = async () => {
        try {
            console.log(user.email);
            const response = await axios.get(ENDPOINTS.API.REQUESTS_BY_USER_EMAIL(user.email));
            setRequests(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    useEffect(() => {
        fetchRequests();
    }, [user]);

    if (isLoading) {
        return <p>Loading requests...</p>;
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        {requests.length > 0 ? requests.map(request => (
                            <UserRequestCard key={request.request_id} userRequest={request} />
                        )) : <p>No requests found. Go bet on some fixtures!</p>}
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            
        </div>
    )
}

const UserRequestsPage = () => {
    return (
        <div>
            <h1>User Requests</h1>
            <UserRequestsList />
        </div>
    );
}

export default UserRequestsPage;