import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const AppNavbar = ({ isAuthenticated, logout }) => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">CoolGoat App</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/fixtures">
              <Nav.Link>Fixtures</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/requests">
              <Nav.Link>Requests</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/wallet">
              <Nav.Link>Wallet</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="ml-auto">
            {isAuthenticated ? (
              <NavDropdown title="Profile" id="basic-nav-dropdown">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Profile Settings</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/wallet">
                  <NavDropdown.Item>Wallet</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/register">
                  <Nav.Link>Register</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
