import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { Auth0Provider } from '@auth0/auth0-react';
import Navbar from './components/Navbar';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-k8isc1a1s4ms6dur.us.auth0.com" // Usa el AUTH0_DOMAIN desde config
      clientId="Mac3b2pTTBQi2yD3qvkD2Sx4qKcvfRJM" // Usa el AUTH0_CLIENT_ID desde config
      authorizationParams={{
        redirect_uri: "https://coolgoat.ink/loginredirect",
        audience: "https://dev-k8isc1a1s4ms6dur.us.auth0.com/api/v2/", // Usa el audience desde config
        scope: "openid profile email read:current_user update:current_user_metadata",
      }}
    >
      <BrowserRouter>
        <Navbar />
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
