import { Card } from 'react-bootstrap';
import './UserRequestCard.css';

const UserRequestCard = ({ userRequest }) => {
    return(
        <Card className={`my-4 ${userRequest.validated ? 'card-validated' : ''}`}>
            <Card.Body>
                <Card.Title> Request n° {userRequest.request_id}</Card.Title>
                {userRequest.result == '---' ? <Card.Text>Draw</Card.Text> : <Card.Text>Winner: {userRequest.result}</Card.Text>}
                <Card.Text>Bonds: {userRequest.quantity}</Card.Text>
                {userRequest.validated ? <Card.Text>Validated!!</Card.Text> : <Card.Text>Pending...</Card.Text>}
            </Card.Body>
        </Card>
    )
}

export default UserRequestCard;