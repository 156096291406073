import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FixturesFilter from '../components/FixturesFilter';
import PageSelector from '../components/PageSelector';
import FixtureCard from '../components/FixtureCard';
import FixtureModal from '../components/FixtureModal'; 
import { ENDPOINTS } from '../config/endpoints';
import { Link } from 'react-router-dom';
import LoginButton from '../components/LoginButtton';
import LogoutButton from '../components/LogoutButton';
import Profile from '../components/Profile';
import { useAuth0 } from '@auth0/auth0-react';

const FixturesPage = () => {
  const [fixtures, setFixtures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState({});
  const [pageSize, setPageSize] = useState(25);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  
  const [selectedFixture, setSelectedFixture] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isLoading, isAuthenticated } = useAuth0();

  const fetchFixtures = async (page = 1, filters = {}, pageSize = 25) => {
    try {
      const params = { page, page_size: pageSize, ...filters };
      
      // Realiza la solicitud a la API con el token
      const response = await axios.get(ENDPOINTS.API.FIXTURES, {
        params,
      });

      setFixtures(response.data.results);
      setTotalPages(Math.ceil(response.data.count / pageSize));
      setHasNextPage(!!response.data.next);
      setHasPreviousPage(!!response.data.previous);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching fixtures:', error);
    }
  };

  const handleFilter = (newFilters) => {
    setFilters(newFilters);
    fetchFixtures(1, newFilters, pageSize);
  };

  const handlePageChange = (page) => {
    fetchFixtures(page, filters, pageSize);
  };

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setPageSize(newSize);
    fetchFixtures(1, filters, newSize); 
  };

  const handleDetailsClick = (fixtureId) => {
    const fixture = fixtures.find(f => f.id === fixtureId);
    setSelectedFixture(fixture);
    setIsModalOpen(true); 
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFixture(null);
  };

  useEffect(() => {
    fetchFixtures(currentPage, filters, pageSize);
  }, [currentPage, filters, pageSize]);

  if (isLoading) {
    return <div>Loading...</div>;  // Display loading indicator
  }


  return (
    <div className="fixtures-page">
      {isAuthenticated ?  <LogoutButton /> : <LoginButton />}

      <Profile />
      <h1>All the fixtures!!</h1>
      
      <button onClick={() => setIsFilterVisible(!isFilterVisible)}>
        {isFilterVisible ? 'Hide Filters' : 'Show Filters'}
      </button>

      {isFilterVisible && <FixturesFilter onFilter={handleFilter} />}

      <div className="fixtures-grid">
        {fixtures.map(fixture => (
          <FixtureCard 
            key={fixture.id} 
            fixture={fixture} 
            onDetailsClick={handleDetailsClick}
          />
        ))}
      </div>

      <div className="page-size-selector">
        <label htmlFor="pageSize">Page Size:</label>
        <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      <PageSelector
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
      />

      <FixtureModal 
        show={isModalOpen} 
        handleClose={closeModal} 
        fixture={selectedFixture} 
      />
    </div>
  );
};

export default FixturesPage;
